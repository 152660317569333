'use client';

import NewCustomerForm from '@forms/newCustomer';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@ngg/icons';
import type { Form, KeyValue, Maybe } from '@server/gql/graphql';
import { getCookie, setCookie } from 'cookies-next';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import type { Dictionary } from 'types/common';

import { useNewCustomerStore } from '@/stores/newCustomerStore';
import { createDictionary } from '@/utils/common';

export default function NewCustomerDiscountPopup({
    dictionary,
    form,
}: {
    dictionary: Dictionary;
    form?: Maybe<Form>;
}) {
    const { dialog, setDialog } = useNewCustomerStore((state) => state);
    const { tooltip, setTooltip } = useNewCustomerStore((state) => state);

    const onHide = useCallback(() => {
        setTooltip(false);
        setCookie('ng_newsletter', true, { maxAge: 60 * 60 * 24 * 7 });
    }, [setTooltip]);

    const onClose = useCallback(() => {
        setDialog(false);
        setTooltip(true);
    }, [setDialog, setTooltip]);

    const onOpen = useCallback(() => {
        setDialog(true);
        setTooltip(false);
    }, [setDialog, setTooltip]);

    useEffect(() => {
        setTooltip(getCookie('ng_newsletter')?.valueOf() !== true);
    }, [setTooltip]);

    const pathname = usePathname();

    const isBlacklistUrl = getIsBlacklistUrl(pathname);
    if (isBlacklistUrl) {
        return null;
    }

    const formDictionary = createDictionary(
        form?.dictionaryCollection?.items as KeyValue[],
    );

    return (
        <>
            <Transition
                show={tooltip}
                appear
                enter="transform transition duration-300"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
                className="fixed bottom-0 left-0 z-10 p-4">
                <div
                    role="tooltip"
                    className="relative flex max-w-[44vw] items-center bg-black text-sm text-white xs:max-w-[55vw]">
                    <button
                        type="button"
                        className="py-4 pl-4 text-left leading-none"
                        onClick={onOpen}>
                        {dictionary.subscribeForDiscount}
                    </button>
                    <button type="button" className="p-4" onClick={onHide}>
                        <XIcon />
                        <span className="sr-only">
                            {dictionary?.close ?? 'Close'}
                        </span>
                    </button>
                </div>
            </Transition>
            <Dialog open={dialog} onClose={onClose} className="relative z-40">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Dialog.Panel className="relative max-w-screen-md bg-black px-8 py-10 text-white">
                            <button
                                type="button"
                                onClick={onClose}
                                className="absolute right-0 top-0 p-4">
                                <XIcon className="text-base" />
                            </button>
                            <NewCustomerForm
                                dictionary={formDictionary}
                                onClose={onClose}
                                classNames={{
                                    instagramButton:
                                        'border-white hover:bg-white hover:text-black hover:border-white text-white',
                                    submitButton:
                                        'btn-inverted hover:bg-black  hover:text-white hover:border-white',
                                    emailInput: 'placeholder:text-white',
                                    selectWrapper: 'bg-black text-white border',
                                    selectOption: 'ui-active:bg-gray-100/10',
                                }}
                            />
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

const BLACKLIST_URLS = ['/checkout'];

function getIsBlacklistUrl(pathname: string): boolean {
    if (!pathname) {
        return false;
    }

    return BLACKLIST_URLS.some((x) => pathname === x || pathname.endsWith(x));
}
