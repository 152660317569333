'use client';

import type { AuthState } from '@context/authContext';
import { useQuery } from '@tanstack/react-query';

import LoginView from './LoginView';
import ResetView from './ResetView';

export default function LoginCard() {
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });
    if (data?.reset) return <ResetView />;
    return <LoginView />;
}
