'use client';

type FetchArgs = Parameters<typeof fetch>;

export function enableClientDiagnostics() {
    if (process.env.NEXT_PUBLIC_ENABLE_DIAGNOSTICS !== 'true') {
        return;
    }
    if (typeof window === 'undefined' || typeof window.fetch === 'undefined') {
        return;
    }

    const originalFetch = window.fetch;

    if (!!(originalFetch as any)['__diagnostics']) {
        return null;
    }

    const diagnosticsFetch = async (...args: FetchArgs) => {
        // eslint-disable-next-line no-console
        console.debug(
            'Client fetch called:',
            process.env.NEXT_PUBLIC_ENABLE_DIAGNOSTICS_FULL ? args : args[0],
        );

        return originalFetch(...args);
    };

    diagnosticsFetch['__diagnostics'] = true;

    window.fetch = diagnosticsFetch;
}
