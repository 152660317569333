import { create } from 'zustand';

type NewCustomerStore = {
    dialog: boolean;
    setDialog: (open: boolean) => void;
    tooltip: boolean;
    setTooltip: (open: boolean) => void;
};

export const useNewCustomerStore = create<NewCustomerStore>((set) => ({
    dialog: false,
    setDialog: (open: boolean) => set({ dialog: open }),
    tooltip: false,
    setTooltip: (open: boolean) => set({ tooltip: open }),
}));
