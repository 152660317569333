'use client';

import { useMarket } from '@app/_context';
import { Input } from '@components/inputs';
import Link from '@components/Link';
import { Heading } from '@components/ui/Heading/Heading';
import { Paragraph } from '@components/ui/Paragraph/Paragraph';
import type { AuthState } from '@context/authContext';
import { useAuth } from '@context/authContext';
import { useSiteInfo } from '@context/siteInfoContext';
import { Button } from '@ngg/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { FormEvent } from 'react';
import { useState } from 'react';

import { cn } from '@/lib/utils';

export default function ResetView() {
    const [sent, setSent] = useState(false);
    const { locale } = useMarket().state;
    const queryClient = useQueryClient();
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });
    const { resetPassword } = useAuth();
    const { globalDictionary, myPagesRegisterPage } = useSiteInfo();

    const { mutate } = useMutation({
        mutationKey: ['auth'],
        mutationFn: (value: Partial<AuthState>) => {
            queryClient.setQueryData(['auth'], (old: AuthState) => ({
                ...old,
                ...value,
            }));
            return Promise.resolve();
        },
    });

    const handleReset = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const email = formData.get('email')?.toString();
        if (!email) return mutate({ error: 'Missing email' });
        await resetPassword(email);
        setSent(true);
    };

    return (
        <>
            <Heading size={5} className={cn('font-bold')}>
                {globalDictionary.forgotPassword}
            </Heading>
            {sent ? (
                <>
                    <Paragraph className="my-4 text-sm">
                        {globalDictionary.passwordResetSuccess}
                    </Paragraph>
                    <button
                        type="button"
                        onClick={() => mutate({ reset: false, login: true })}
                        className="mt-4 text-sm text-grey-300 underline hover:opacity-70">
                        {globalDictionary.backToLogin}
                    </button>
                </>
            ) : (
                <>
                    <Paragraph className="my-4 text-sm">
                        {globalDictionary.passwordResetInstructions}
                    </Paragraph>
                    <form className="mt-6 space-y-4" onSubmit={handleReset}>
                        <Input
                            classNames={{ wrapper: 'w-full' }}
                            name="email"
                            id="email"
                            type="email"
                            label={`${globalDictionary?.formEmailLabel}*`}
                        />

                        <Button
                            isLoading={data?.loading}
                            type="submit"
                            className="btn-primary"
                            fullWidth>
                            {globalDictionary?.send}
                        </Button>

                        <button
                            type="button"
                            onClick={() =>
                                mutate({ reset: false, login: true })
                            }
                            className="mt-4 text-sm text-grey-300 underline hover:opacity-70">
                            {globalDictionary.backToLogin}
                        </button>

                        <hr className="w-full border-grey-150" />

                        <p className="text-sm text-grey-300">
                            {globalDictionary.noAccount}{' '}
                            {myPagesRegisterPage?.slug ? (
                                <Link
                                    locale={locale}
                                    className="text-black underline"
                                    href={myPagesRegisterPage.slug}
                                    onClick={() =>
                                        mutate({ reset: false, login: false })
                                    }>
                                    {globalDictionary.register}
                                </Link>
                            ) : null}
                        </p>
                    </form>
                </>
            )}
        </>
    );
}
