import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { create } from 'zustand';

type CurrentPathStore = {
    previousPath?: string;
    currentPath?: string;
    previousParams?: string;
    currentParams?: string;
};

const currentPathStore = create<CurrentPathStore>(() => ({
    previousPath: undefined,
    currentPath: undefined,
    previousParams: undefined,
    currentParams: undefined,
}));

// Use as a hook to get previousPath and currentPath
export const useCurrentPath = () => {
    return currentPathStore((state) => state);
};

// Use this wherever you like to get the currentPath
export const getCurrentPath = () => {
    return currentPathStore.getState();
};

// Only use this once in layout.tsx
export const useCurrentPathInitializer = () => {
    const pathname = usePathname();
    const params = useSearchParams();
    const { currentPath, currentParams } = useCurrentPath();

    useEffect(() => {
        if (currentPath !== pathname) {
            currentPathStore.setState((state) => ({
                ...state,
                currentPath: pathname,
                previousPath: currentPath,
                currentParams: params.toString(),
                previousParams: currentParams,
            }));
        }
    }, [pathname, params, currentParams, currentPath]);
};
