'use client';

import type { AuthState } from '@context/authContext';
import { useAuth } from '@context/authContext';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@ngg/icons';
import { useQuery } from '@tanstack/react-query';
import { Fragment } from 'react';

import LoginCard from './LoginCard';

export default function LoginDialog() {
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });
    const { hideLogin } = useAuth();

    return (
        <Transition show={Boolean(data?.login)} as={Fragment} appear>
            <Dialog
                onClose={hideLogin}
                className="fixed inset-0 z-50 grid place-content-center p-4">
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-in duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black/70" />
                </Transition.Child>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    as={Fragment}>
                    <Dialog.Panel className="relative w-full max-w-[420px] overflow-y-auto bg-white px-4 py-10 md:px-6 md:pt-12">
                        <button
                            type="button"
                            onClick={hideLogin}
                            className="absolute right-3 top-3 text-base">
                            <XIcon />
                        </button>
                        <LoginCard />
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    );
}
